// import topic from '../topic'
// export const mstkAPI = {
//     getMstks: topic.mstkTopic + '/get_mstks',
//     getMstkById: topic.mstkTopic + '/get_mstk_by_id',
//     addMstk: topic.mstkTopic + '/add_mstk',
//     editMstk: topic.mstkTopic + '/edit_mstk',
//     deleteMstkByIds: topic.mstkTopic + '/delete_mstk_by_ids'
// }
import topic from '../topic'
export const mstkAPI = {
    getMstks: topic.mstkTopic + '/get_mstks',
    getPushMstks9001: topic.mstkTopic + '/get_push_mstks_9001',
    updateScanById: topic.mstkTopic + '/update_scan_by_id',
    destroy_mstk_by_ids: topic.mstkTopic + '/destroy_mstk_by_ids',
}