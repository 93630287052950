<template>
  <div class="vg_wrapper">
    <el-card>
      <el-row>
        <el-col>
                <el-button type="info" size="small" class="vd_export" @click="buttonRefresh()"><i class="el-icon-refresh-right"></i> 刷新</el-button>
        </el-col>
      </el-row>
      <el-row>
        <el-col :md="24">
          <el-table ref="multiTable" :data="tableData" border v-loading="loading" @row-dblclick="dbClickJp" class="mstkTab">
            <el-table-column label="物料编号" prop="mtrl_no"/>
            <el-table-column label="物料名称" prop="mtrl_name"  :show-overflow-tooltip='true' />
            <el-table-column label="物料类型" prop="mtrl_type"  :show-overflow-tooltip='true'  :formatter="formatLeavType"/>
            <el-table-column label="物料单位" prop="mtrl_unit"  :show-overflow-tooltip='true'/>
            <el-table-column label="剩余库存" prop="mstk_num" :formatter="formatMstkNum"/>
            <el-table-column label="安全库存" prop="mtrl_stock_limit" :formatter="formatMtrlStockLimit"/>
            <el-table-column label="操作" align="center" width="80px">
              <template slot-scope="scope">
                <el-button type="text" size="mini" @click="toCompleted(scope.$index,scope.row)" v-show="scope.row.scan">完成</el-button>
                <span v-show="!scope.row.scan" class="vd_completed">已完成</span>
              </template>
            </el-table-column>
          </el-table>
        </el-col>
      </el-row>
    </el-card>
  </div>
</template>

<script>
import {get,post} from "@api/request";
import {mstkAPI} from "@api/modules/mstk";
export default {
  name: "MstkPush9001",
  data(){
    return{
      tableData: [],
      loading: true,
      isEditObj: [],
    }
  },
  created() {
    this.initData()
  },
  mounted() {
    for (let i in this.tableData) {
      let scan = "edit"+i;
      this.isEditObj.push({
        scan:true
      });
    }
  },
  methods: {
    initData() {
      this.getPushList()
    },
    // 物料类型
    formatLeavType(row){
      if(row.mtrl_type === 0){
        return '原面料'
      } else if(row.mtrl_type === 1){
        return '辅料'
      } else if(row.mtrl_type === 2){
        return '包材'
      }
    },
    formatMstkNum(row){
      return this.helper.haveFour(row.mstk_num)
    },
    formatMtrlStockLimit(row){
      return this.helper.haveFour(row.mtrl_stock_limit)
    },
    //获取信息
    getPushList(){
      get(mstkAPI.getPushMstks9001)
          .then(res=>{
            if(res.data.code === 0){
              this.loading = false
              this.tableData = res.data.data.list
            } else {
              let mg = res.data.msg;
              let tp = 'error';
              this.$message({message:mg,type:tp});
            }
          })
          .catch(res=>{
            let mg = res.data.msg;
            let tp = 'error';
            this.$message({message:mg,type:tp});
          })
    },
    //操作
    toCompleted(index,row){
      post(mstkAPI.updateScanById,{mstk_id: row.mstk_id})
          .then(res => {
            if (res.data.code === 0) {
              this.$set(row, 'scan', false)
            } else {
              let mg = res.data.msg;
              let tp = 'error';
              this.$message({message: mg, type: tp});
            }
          }).catch(res => {
        let mg = res.data.msg;
        let tp = 'error';
        this.$message({message: mg, type: tp});
      })
    },
    // 双击
    dbClickJp(row){
      this.jump('/mstk_list',{perm_id:this.$route.query.perm_id,form_id:row.mstk_id,mtrl_no:row.mtrl_no})
    },
    buttonRefresh(){
      this.getPushList()
    }
  }
}
</script>

<style scoped lang="scss">
.vd_completed{
  color: $color-secondary-text;
}
.mstkTab {
  margin-top: 10px;
  cursor: pointer;
}
</style>